App.handleMobilenum = function() {
    $('.js--mobilenum').on('click', function(event) {

        if ( $(window).width() > 480) {
            event.preventDefault();
            $(this).find('.header__link-text').toggleClass('js--active');
        }

    });
};
